import React, { useState, useEffect, useContext } from "react";
import "./formInscription.scss";
import Input from "../../atoms/input/input";
import Button from "../../atoms/button/button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from '@contentful/rich-text-types';
import useLoginGigya from "../../../shared/hooks/useLoginGigya";
import Loading from "../../atoms/loading/loading";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import Modal from "../../atoms/modal/modal";
import { postUser } from "../../../services/form-intermediacion";
import GlobalContext from "../../../contexts/globalContext";
import { Page, Text, Document, pdf, StyleSheet } from "@react-pdf/renderer";
import {  getLegalAspectsByVerticalId, postLegalAspects} from "../../../services/legalAspects.js";
import { towns }from '../../../services/townsCreo.js'
import moment from 'moment';

interface LegalAspectsInterface {
   id?: number;
   name?: string;
   description?: string;
}

const FormInscription = (props: any) => {
   const { gigyaAccount } = useLoginGigya();
   const arrForm = props;
   const defaultFormValues = {
      fullName: "",
      identityDocument: "",
      expeditionPlace: "",
      municipality: "",
      binaries: [],
   };

   const titles = {};

   const today = moment(Date.now()).format('YYYY-MM-DD');
   const todayFull = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

   const { isBrowser } = useBrowserMode();
   const [legalAspects, setlegalAspects] = useState<LegalAspectsInterface>({});
   const [legal, setlegal] = useState([]);
   const [formValues, setFormValues] = useState(defaultFormValues);
   const Context = useContext(GlobalContext);
   const [titleValues, setTitleValues] = useState(titles);
   const [checkBox, setCheckBox] = useState(false);
   const [secondCheckBox, setsecondCheckBox] = useState(false);
   const [isService, setisService] = useState(true);
   const [loading, setloading] = useState(false);
   const [errorCatch, seterrorCatch] = useState(false);
   const [isEnd, setisEnd] = useState(false);
   const [verify, setVerify] = useState(false)
   const [firstTime, setfirstTime] = useState(true)
   const [validInscription, setvalidInscription] = useState(0)
   const [iconWarning, seticonWarning] = useState(false)

   const getLegalAspects = async () => {
      try {
         const aspects = await getLegalAspectsByVerticalId(5);
         const found = aspects.data.data.find((el) => el.name === "INSCRIPTION");
         setlegalAspects(found);
      } catch (error) {
         console.log(error);
      }
   };

   // Get legal from Contentful
   const getLegal = () => {
      const modalText: any = documentToReactComponents(arrForm[7].descripcion.json);
      const joinedText = modalText[0].props.children.join("");
      const replaceValues = joinedText.replaceAll("%%%%", "__________");
      const desc = replaceValues.split("$$");
      setlegal(desc);
   }

   const postLegalAspectsInfo = async () => {
      const body = {
         id: legalAspects.id,
         documentType: "02",
         document: formValues.identityDocument,
         email: null,
      };
      try {
         await postLegalAspects(body);
      } catch (error) {
         console.log(error);
      }
   };

   const updateFormValues = (value: any, name?: any) => {``
        name && setFormValues({ ...formValues, [name]: value.toUpperCase().replace(/[\r\n]/gm, '') });
   };

   const updateTitleValues = () => {
      for (let i = 0; i < 1; i++) {
         arrForm[i].referenciaFormulario.slice(0, 4).map((inp) => {
            let name = inp.nombreId;
            if (!["attachments","personName","idNum","idNumType","email",].includes(name)) {
               setTitleValues((state) => {
                  let newState = state;
                  newState[name] = inp.inputPrincipal;
                  return newState;
               });
            }
         });
      }
   };

   //Upload input values to the back
   const handleSendMessage = async () => {
      try {
         setloading(true);
         let payloadResponses: any[] = [];
         for (let key in titleValues) {
            let response = {
               contentfulId: key,
               questionDescription: titleValues[key],
               answer: formValues[key],
               type: requestType(key),
            };
            payloadResponses.push(response);
         }

         let date = {
            contentfulId: 'createDateInscription',
            questionDescription: 'Fecha de inscripción',
            answer: todayFull,
            type: 'DATETIME'
         }

         payloadResponses.push(date);

         let payload = {
            formId: 3,
            user: {
               document: formValues.identityDocument,
               documentType: "02",
               fullname: formValues.fullName
            },
            responseList: payloadResponses,
            attachmentList: formValues.binaries
         };

         const req = await postUser(payload, "Payload");
         postLegalAspectsInfo();

         if (req.status === 200 && req.data.status === 200) {
            setFormValues(defaultFormValues);
            setisService(false);
            setloading(false);
            setvalidInscription(req.data.status)
         } else if (req.status === 200 && (req.data.status === 3001 || req.data.status === 3002 || req.data.status === 3003 || req.data.status === 3004)) {
            setvalidInscription(req.data.status)
            seticonWarning(true)
            setisService(false);
            setloading(false);
         } else {
            setisService(false);
            seterrorCatch(true);
         }
         setisEnd(true);
      } catch (error) {
         setloading(false);
         seterrorCatch(true);
         setisService(false);
         console.log(error);
      }
   };

   const requestType = (key: string): string => {
      if (key === "email") return "EMAIL";
      if (key === "birthDate") return "DATE";
      if (key === "cellPhone" || key === "phone" || key === "secondCellPhone")
         return "NUMBER";
      return "TEXT";
   };

   const validInscriptionRender = () => {
      switch (validInscription) {
         case 3001:
            return documentToReactComponents(arrForm[2].descripcion.json);
         case 3002:
            return documentToReactComponents(arrForm[6].descripcion.json);
         case 3003:
            return documentToReactComponents(arrForm[3].descripcion.json);
         case 3004:
            return documentToReactComponents(arrForm[4].descripcion.json);
         case 200:
            return documentToReactComponents(arrForm[5].descripcion.json);
         default:
            break;
      }
   }

   const ga_push = () => {
      window.ga_debug = { trace: true };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "ClicFormulario ",
              category: "FormularioInscripcion ",
              action: "Enviarexitoso ",
            });
   }

   const validateForm = () => {
      return (
         formValues.fullName === "" || formValues.identityDocument === "" || formValues.expeditionPlace === "" || formValues.municipality === "" || !checkBox
      )
   };

   const validateTerms = () => {
      return !secondCheckBox;
   };

   const getInputVar = (_input, _i) => {
      if (_input.tipoCampo === "select" && _input.nombreId === "militaryCard") {
         return (
            <div
               className={`input__container ${formValues.gender === "F" && _input.nombreId === "militaryCard" ? "hide" : ""}`}
               key={`input-${_i}`}
            >
               <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
               <Input
                  tabIndex={1 + _i}
                  key={`inp${_i}`}
                  title={_input.inputPrincipal}
                  type={_input.tipoCampo}
                  nameId={_input.nombreId}
                  value={formValues[_input.nombreId]}
                  handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  isError={errorDetected(_input)}
                  errorMessage={_input.mensajeError}
                  options={_input.listaSelect || null}
                  placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                  gigyaAccount={gigyaAccount}
               />
            </div>
         );
      } else if (_input.tipoCampo === "select" && (_input.nombreId === "disabilityType" || _input.nombreId === "certificateDisability")) {
         return (
            <div
               className={`input__container ${
                  (formValues.disability === "No" && (_input.nombreId === "disabilityType" || _input.nombreId === "certificateDisability"))  ? "hide" : ""}`}
               key={`input-${_i}`}
            >
               <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
               <Input
                  tabIndex={1 + _i}
                  key={`inp${_i}`}
                  title={_input.inputPrincipal}
                  type={_input.tipoCampo}
                  nameId={_input.nombreId}
                  value={formValues[_input.nombreId]}
                  handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  isError={errorDetected(_input)}
                  errorMessage={_input.mensajeError}
                  options={_input.listaSelect || null}
                  placeholder={ _input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                  gigyaAccount={gigyaAccount}
               />
            </div>
         );
      } else if (
         (_input.tipoCampo === "select" || _input.tipoCampo === "number") &&
         (_input.nombreId === "manyChildren" || _input.nombreId === "ageChildren")
      ) {
         return (
            <div
               className={`input__container ${(formValues.children === "No" && (_input.nombreId === "manyChildren" || _input.nombreId === "ageChildren"))  ? "hide" : "" }`}
               key={`input-${_i}`}
            >
               <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
               <Input
                  tabIndex={1 + _i}
                  key={`inp${_i}`}
                  title={_input.inputPrincipal}
                  type={_input.tipoCampo}
                  nameId={_input.nombreId}
                  value={formValues[_input.nombreId]}
                  handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  isError={errorDetected(_input)}
                  errorMessage={_input.mensajeError}
                  options={_input.listaSelect || null}
                  placeholder={ _input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                  gigyaAccount={gigyaAccount}
               />
            </div>
         );
      } else if (
         (_input.tipoCampo === "select" || _input.tipoCampo === "text" ) &&
         (_input.nombreId === "workTimeExperience" || _input.nombreId === "lastWork")
      ) {
         return (
            <div
               className={`input__container ${(formValues.workExperience === "No" && (_input.nombreId === "workTimeExperience" ||_input.nombreId === "lastWork"))  ? "hide" : "" }`}
               key={`input-${_i}`}
            >
               <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
               <Input
                  tabIndex={1 + _i}
                  key={`inp${_i}`}
                  title={_input.inputPrincipal}
                  type={_input.tipoCampo}
                  nameId={_input.nombreId}
                  value={formValues[_input.nombreId]}
                  handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  isError={errorDetected(_input)}
                  errorMessage={_input.mensajeError}
                  options={_input.listaSelect || null}
                  placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                  gigyaAccount={gigyaAccount}
               />
            </div>
         );
      } else if (_input.tipoCampo === "number" && _input.nombreId === "ageChildren1") {
            return (
               <div
                  className={`input__container ${(formValues.children === 'Si' && (formValues.manyChildren === "1" || formValues.manyChildren === "2" || formValues.manyChildren === "3" || formValues.manyChildren === "4" || formValues.manyChildren === "5")) && _input.nombreId === "ageChildren1" ? "" : "hide"}`}
                  key={`input-${_i}`}
               >
                  <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
                  <Input
                     tabIndex={1 + _i}
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     type={_input.tipoCampo}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     isError={errorDetected(_input)}
                     errorMessage={_input.mensajeError}
                     options={_input.listaSelect || null}
                     placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                     gigyaAccount={gigyaAccount}
                  />
               </div>
            );
      } else if (_input.tipoCampo === "number" && _input.nombreId === "ageChildren2") {
         return (
            <div
               className={`input__container ${( formValues.children === 'Si' && (formValues.manyChildren === "2" || formValues.manyChildren === "3" || formValues.manyChildren === "4" || formValues.manyChildren === "5")) && _input.nombreId === "ageChildren2" ? "" : "hide"}`}
               key={`input-${_i}`}
            >
               <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
               <Input
                  tabIndex={1 + _i}
                  key={`inp${_i}`}
                  title={_input.inputPrincipal}
                  type={_input.tipoCampo}
                  nameId={_input.nombreId}
                  value={formValues[_input.nombreId]}
                  handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  isError={errorDetected(_input)}
                  errorMessage={_input.mensajeError}
                  options={_input.listaSelect || null}
                  placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                  gigyaAccount={gigyaAccount}
               />
            </div>
         );
   } else if (_input.tipoCampo === "number" && _input.nombreId === "ageChildren3") {
      return (
         <div
            className={`input__container ${(formValues.children === 'Si' && (formValues.manyChildren === "3" || formValues.manyChildren === "4" || formValues.manyChildren === "5")) && _input.nombreId === "ageChildren3" ? "" : "hide"}`}
            key={`input-${_i}`}
         >
            <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
            <Input
               tabIndex={1 + _i}
               key={`inp${_i}`}
               title={_input.inputPrincipal}
               type={_input.tipoCampo}
               nameId={_input.nombreId}
               value={formValues[_input.nombreId]}
               handleChange={(e) => updateFormValues(e, _input.nombreId)}
               isError={errorDetected(_input)}
               errorMessage={_input.mensajeError}
               options={_input.listaSelect || null}
               placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
               gigyaAccount={gigyaAccount}
            />
         </div>
      );
} else if (_input.tipoCampo === "number" && _input.nombreId === "ageChildren4") {
   return (
      <div
         className={`input__container ${(formValues.children === 'Si' && (formValues.manyChildren === "4" || formValues.manyChildren === "5")) && _input.nombreId === "ageChildren4" ? "" : "hide"}`}
         key={`input-${_i}`}
      >
         <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
         <Input
            tabIndex={1 + _i}
            key={`inp${_i}`}
            title={_input.inputPrincipal}
            type={_input.tipoCampo}
            nameId={_input.nombreId}
            value={formValues[_input.nombreId]}
            handleChange={(e) => updateFormValues(e, _input.nombreId)}
            isError={errorDetected(_input)}
            errorMessage={_input.mensajeError}
            options={_input.listaSelect || null}
            placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
            gigyaAccount={gigyaAccount}
         />
      </div>
   );
} else if (_input.tipoCampo === "number" && _input.nombreId === "ageChildren5") {
   return (
      <div
         className={`input__container ${formValues.children === 'Si' && formValues.manyChildren === "5" && _input.nombreId === "ageChildren5" ? "" : "hide"}`}
         key={`input-${_i}`}
      >
         <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
         <Input
            tabIndex={1 + _i}
            key={`inp${_i}`}
            title={_input.inputPrincipal}
            type={_input.tipoCampo}
            nameId={_input.nombreId}
            value={formValues[_input.nombreId]}
            handleChange={(e) => updateFormValues(e, _input.nombreId)}
            isError={errorDetected(_input)}
            errorMessage={_input.mensajeError}
            options={_input.listaSelect || null}
            placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
            gigyaAccount={gigyaAccount}
         />
      </div>
   );
} else {
         return (
            <div className="input__container" key={`input-${_i}`}>
               <h6 className="input__container__h6">{_input.inputPrincipal}</h6>
               <Input
                  tabIndex={1 + _i}
                  key={`inp${_i}`}
                  title={_input.inputPrincipal}
                  type={_input.tipoCampo}
                  nameId={_input.nombreId}
                  value={formValues[_input.nombreId]}
                  handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  isError={errorDetected(_input)}
                  errorMessage={_input.mensajeError}
                  options={_input.tipoCampo === 'predict' ? towns : _input.listaSelect}
                  placeholder={_input.tipoCampo === "select" && _input.nombreId === "state" ? _input.listaSelect[0] : "" }
                  gigyaAccount={gigyaAccount}
               />
            </div>
         );
      }
   };

   //Create a new pdf file and then convert it to base64 format
   const pdfFile = async () => {
      const styles = StyleSheet.create({
         page: {
            marginTop: 40
         },

         title: {
            margin: 0.5,
            fontSize: 10,
            textAlign: 'center',
         },

         subtitle1: {
            marginTop: 20,
            marginRight: 50,
            marginLeft: 50,
            fontSize: 10,
            textAlign: 'justify',
            justifyContent:'flex-start',
            width: 'auto'
         },

         subtitle2: {
            marginTop: 0.5,
            marginRight: 50,
            marginLeft: 50,
            fontSize: 10,
            textAlign: 'justify',
            justifyContent:'flex-start',
            width: 'auto'
         },

         main: {
            marginTop: 20,
            marginRight: 80,
            marginLeft: 80,
            fontSize: 10,
            textAlign: 'center',
         },

         content: {
            marginTop: 10,
            marginRight: 50,
            marginLeft: 50,
            fontSize: 10,
            textAlign: 'justify',
            justifyContent:'center',
            width: 'auto'
         },
       });

      const Doc = (
         <Document>
           <Page style={styles.page}>
               <Text style={styles.title}>
                  {legal[0]}
               </Text>
               <Text style={styles.title}>
                  {legal[1]}
               </Text>
               <Text style={styles.title}>
                  {legal[2]}
               </Text>
               <Text style={styles.subtitle1}>
                  {legal[3].replace('__________', `${today}`)}
               </Text>
               <Text style={styles.subtitle2}>
                  {legal[4].replace('__________', `${formValues.municipality}`)}
               </Text>
               <Text style={styles.main}>
                  {legal[5]}
               </Text>
               <Text style={styles.content}>
                  {legal[6].replace('__________', `${formValues.fullName}`).replace('__________', `${formValues.identityDocument}`).replace('__________',`${formValues.expeditionPlace}` )}
               </Text>
               {
                  legal.slice(7, 38).map((el, i) => <Text style={styles.content} key={i}>{el}</Text>)
               }
               <Text style={styles.content}>
                  Nombre: {formValues.fullName + " "}
                  Cédula: {formValues.identityDocument}
               </Text>
               {
                  legal.slice(38, 39).map((el, i) => <Text style={styles.content} key={i}>{el}</Text>)
               }
           </Page>
         </Document>
       )

      const blob = await pdf(Doc).toBlob();
      const reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
      const base64data = reader.result

      //Set the base64 encoding to the state
      setFormValues((prev: any) => {
        return {
           ...prev,
           binaries: [
              ...prev.binaries,
              {
                 fileBase64: base64data.split(",")[1],
                 key: "TYC",
                 type: "pdf"
              },
           ],
        };
     });
    };
   }

   const errorDetected = (_input): boolean => {

      if (_input.nombreId === "fullName" && formValues.fullName.length === 0 && verify) {
         return true
      }

      if (_input.nombreId === "identityDocument" && formValues.identityDocument.length === 0 && verify) {
         return true
      }

      if (_input.nombreId === "expeditionPlace" && formValues.expeditionPlace.length === 0 && verify ) {
         return true
      }

      if (_input.nombreId === "municipality" && formValues.municipality.length === 0 && verify) {
         return true
      }

      return false
   }

   useEffect(() => {
      if (isBrowser && Context.gigyaContext.gigyaAccount) {
         props.setIsUser(true);
         var gender: string = "";
         if (Context.gigyaContext.gigyaAccount.profile.gender === "f") { gender = "F" }
         if (Context.gigyaContext.gigyaAccount.profile.gender === "m") { gender = "M" }

         setFormValues({
            ...formValues,
            fullName: `${Context.gigyaContext.gigyaAccount.profile.firstName} ${Context.gigyaContext.gigyaAccount.data.secondName} ${Context.gigyaContext.gigyaAccount.profile.lastName} ${Context.gigyaContext.gigyaAccount.data.secondLastName}`,
            identityDocument: Context.gigyaContext.gigyaAccount.data.docNumber,
         });

      } else if (formValues.identityDocument) {
         window.location.href = "/inscription";
      }
   }, [Context.gigyaContext.gigyaAccount]);

   useEffect(() => {
      getLegalAspects();
      getLegal();
   }, []);

   useEffect(() => {
      updateTitleValues();
   }, [formValues.identityDocument]);

   useEffect(() => {
      if ( Context.gigyaContext.gigyaAccount?.UID && !localStorage.getItem("uid-pre") ) {
         localStorage.setItem( "uid-pre", Context.gigyaContext.gigyaAccount?.UID);
         window.location.reload();
      }
   }, [Context.gigyaContext.gigyaAccount]);

   const Span = (children:any) => (<span>{children}</span>)

   const options_message = {
      renderNode: {
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Span(children)
      },
   };

   return (
      <>
            <div className="o-form inscription-form">
               <div className="inscription-form__container">
                  <div className="inscription-form__left">
                     <p className="title-form">
                     {documentToReactComponents(props.description, options_message)}
                     </p>
                     <div id="group0" className="o-form__group--open">
                        <div className="o-form__group-cont inscription">
                           {arrForm &&
                              arrForm[0].referenciaFormulario.slice(0, 4).map((inp, i) =>
                                 getInputVar(inp, i)
                              )}
                        </div>
                     </div>
                  </div>
                  <div className="inscription-form__right">
                     <img src={props.image} alt="Imagen contacto"/>
                  </div>
               </div>
               <div className="inscription-form__bottom">
                  <div className="o-form__bottom">
                     <div className="o-form__bottom-txt">
                        {arrForm && documentToReactComponents(arrForm[1].referenciaFormulario[0].textoTerminos.json)}
                     </div>
                     <div className="o-form__bottom-btns">
                        <div className="o-form__bottom-check">
                           <input
                              type="checkbox"
                              id="cbox1"
                              value="first_checkbox"
                              onChange={(e) => {setCheckBox(e.target.checked); setVerify(true)}}
                           />
                           <label htmlFor="cbox1" onClick={() => setVerify(true)} >{arrForm[1].referenciaFormulario[0].tituloBotonTerminos}</label>
                        </div>
                        <div className="o-form__bottom-btn">
                           {loading ? (
                              <Loading />
                           ) : (
                              <Button
                                 id={"btn-enviar"}
                                 type={"button"}
                                 classname={`secondary `}
                                 text="Continuar"
                                 isDisabled={validateForm()}
                                 gtmMark={"gtmFormularioInscripcionContinuar"}
                                 onClickAction={() => {
                                    setisService(false);
                                    firstTime ? pdfFile() : null;
                                 }}
                              />
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          {errorCatch &&
            <Modal open={true}>
               <div className="o-form-success">
                  <div className="o-form-success__content">
                     <i className="icon__check icon-close" />
                     <h3 className="o-form-success__content__message">
                        Error al enviar la inscripción
                     </h3>
                     <p className="o-form-success__content__info">
                        En este momento no podemos procesar su solicitud, por
                        favor intentelo de nuevo mas tarde.
                     </p>
                     <a href="" className="o-form-success__content__btn">
                        ACEPTAR
                     </a>
                  </div>
               </div>
            </Modal>
            }

            {
               !isService &&
               <Modal open={true} onClose={() => false}>
               {isEnd === false ? (
                  <div className="o-form-success-ins">
                  <i className="icon-close icon" onClick={ () => {setisService(!isService) ; setfirstTime(false) ;document.body.classList.remove("a-modal--open")}}/>
                     <div className="o-form-success-ins__title">
                        <h3>{arrForm[7].tituloGeneral}</h3>
                     </div>
                     <div className="o-form-success-ins__content">
                        <div className="o-form-success-ins__content__info">
                           <p>{legal[0]}</p>
                           <p>{legal[1]}</p>
                           <p>{legal[2]}</p>
                           <p>{legal[3].replace('__________', `${today}`)}</p>
                           <p>{legal[4].replace('__________', `${formValues.municipality}`)}</p>
                           <p>{legal[5]}</p>
                           <p>{legal[6].replace('__________', `${formValues.fullName.toUpperCase()}`).replace('__________', `${formValues.identityDocument}`).replace('__________',`${formValues.expeditionPlace}` )}</p>
                           {legal.slice(7,38).map((el, i) => <p key={i}>{el}</p>) }
                           <p>Nombre: {formValues.fullName}</p>
                           <p>Cédula: {formValues.identityDocument}</p>
                           <p>{legal[38]}</p>
                        </div>
                     </div>
                     <div className="o-form-success-pre__content__footer">
                        <div className="o-form-success-pre__content__footer__bottom--check">
                           <input
                              type="checkbox"
                              id="cbox2"
                              value="second_checkbox"
                              onChange={(e) =>
                                 setsecondCheckBox(e.target.checked)
                              }
                           />
                           <label htmlFor="cbox2">Leí la totalidad del documento y acepto los términos anteriormente expuestos</label>
                        </div>
                        <div className="o-form-success-pre__content__footer__bottom-btn inscription">
                           {loading ? (
                              <Loading />
                           ) : (
                              <Button
                                 id={"btn-enviar"}
                                 type={"button"}
                                 classname={`modal `}
                                 text="Enviar"
                                 isDisabled={validateTerms()}
                                 gtmMark={"gtmFormularioInscripcionEnviar"}
                                 onClickAction={() => handleSendMessage()}
                              />
                           )}
                        </div>
                     </div>
                  </div>
               ) : (
                  <div className="o-form-success-step2">
                     <div className="o-form-success-step2__content">
                        {iconWarning ? <i className="icon__check icon-exclamation-circle1" /> : <i className="icon__check icon-check-circle1" />}
                        <h3 className="o-form-success-step2__content__message">
                           {iconWarning === false ? '¡Felicitaciones!' : '¡Atención!'}
                        </h3>
                        <div className="o-form-success-step2__content__info inscription">
                           {validInscription === 200 || validInscription === 3002 ? "" : `${formValues.fullName.toUpperCase()}` }
                           {validInscription && validInscriptionRender()}
                        </div>
                        <a
                           href=""
                           className="o-form-success-step2__content__btn"
                           onClick={() => validInscription === 200 ? ga_push() : ''}
                        >
                           ACEPTAR
                        </a>
                     </div>
                  </div>
               )}
            </Modal>
            }
      </>
   );
};

export default FormInscription;
