import React, { useState } from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import FormInscription from "../form_inscription/formInscription";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import "./bodyInscription.scss";

interface IBodyContact {
   nodes: {
      tituloPrincipal: string;
      descripcion: { json: any };
      tituloFormulario: string;
      descripcionFormulario: { json:any };
      tituloUbicacion: string;
      tituloBoton: string;
      redireccionBoton: string;
      referenciaUbicaciones:Array <{
         titulo:string;
         tituloUbicacion:string;
         localizacion: {
            lat: number,
            lon: number
         }
      }>;
      referenciaPreguntasFrecuentes: Array<ReferenciaContenido>;
      titulosFormulario: { json: any };
      imagenFormulario: any;
   };
}


const Text = (children:any, classname:string) => (
   <p className={classname}>{children}</p>
)

const Span = (children:any) => (<span>{children}</span>)

const options_subtitle = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children, 'o-contact-inscription__header-subtitle')
   },
};


const options_message = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Span(children)
   },
};

const BodyInscription = (props:IBodyContact) => {
   const [isUser, setIsUser] = useState(false)

   return (
      <div className="o-contact-inscription">
         <Breadcrumb content={`Inicio / ${props.nodes[0].tituloPrincipal}`} />

         <div className={`o-contact-inscription__header`}>
            <h1 className="o-contact-inscription__header-title title-primary">
               {props.nodes[0].tituloPrincipal}
            </h1>
            {documentToReactComponents(props.nodes[0].descripcion.json, options_subtitle)}
            <p className="o-contact-inscription__header-message">
               {props.nodes[0].tituloFormulario}
            </p>
         </div>

         <div className={`o-contact-inscription__cont`}>
            <div className="o-contact-inscription__cont-form">
               <FormInscription {...props.nodes[0].titulosFormulario} 
                  description={props.nodes[0].descripcionFormulario.json} 
                  isUser={isUser} 
                  setIsUser={setIsUser}
                  image={props.nodes[0].imagenFormulario && props.nodes[0].imagenFormulario.file.url}
               /> 
            </div>
         </div>
      </div> 
   );
};

export default BodyInscription;
