import React from "react";
import Layout from "../components/organisms/layout/layout";
import SEO from "../shared/seo";
import BodyInscription from "../components/organisms/body-inscription/bodyInscription";
import { useStaticQuery, graphql } from "gatsby";

const InscriptionPage = () => {
   const data = useStaticQuery(graphql`
   query CONTACT_CONTENT_INS {
      allContentfulContactenos(filter: {titulo: {eq: "Información formulario inscripción"}}) {
        nodes {
          tituloPrincipal
          descripcion {
            json
          }
          tituloFormulario
          descripcionFormulario {
            json
          }
          imagenFormulario {
            title
            file {
              url
            }
            fluid(maxWidth: 960, quality: 75) {
              src
              srcSet
              base64
              aspectRatio
              sizes
            }
          }
          titulosFormulario {
            tituloGeneral
            referenciaFormulario {
              tipoCampo
              inputPrincipal
              mensajeError
              listaSelect
              nombreId
              tituloBotonTerminos
              textoTerminos {
                json
              }
            }
            descripcion {
              json
            }
          }
         }
      }
    }
   `);

   return (
      <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
         <SEO title="Inscripción " />
         <BodyInscription {...data.allContentfulContactenos} />
      </Layout>
   );
};

export default InscriptionPage;